export default [
    {
      path: "/dashboard/admin/funded_mortgage/list",
      props: true,
      name: "ListFundedMortgage",
      meta: { adminOnly: true },
      component: () => import("@/views/admin/funded_mortgage/List.vue"),
    },

    {
      path: "/dashboard/admin/funded_mortgage/read/:id",
      props: true,
      name: "ReadFundedMortgage",
      meta: { adminOnly: true },
      component: () => import("@/views/admin/funded_mortgage/Read.vue"),
    },
]  