export default [
  {
    path: "/dashboard/admin/list-all-properties",
    props: true,
    name: "list_all_properties",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/property/List.vue"),
  },

  {
    path: "/dashboard/admin/property/:viewed_as",
    props: true,
    name: "create_customer_property",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/property/Add.vue"),
  },

  {
    path: "/dashboard/admin/read-property/:id",
    props: true,
    name: "read_property",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/property/Read.vue"),
  },
];
