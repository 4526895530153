export default [
  {
    path: "/dashboard/admin/form/:form_unique_id/read/:company_id",
    props: true,
    name: "main_form",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/form/ReadForm.vue"),
  },
  
  {
    path: "/dashboard/admin/form_response/:form_unique_id/read/:id",
    props: true,
    name: "read_form_response",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/form_response/ReadResponse.vue"),
  },

  // {
  //   path: '/dashboard/admin/form/backup',
  //   props: true,
  //   name: 'read_investor_kyc_response',
  //   meta: { },
  //   component: () => import('@/views/admin/form/backup.vue'),
  // },

  {
    path: "/form_link/:form_unique_id/company/:company_id/read",
    props: true,
    name: "form_link",
    // does not need to be logged in because has token in the link
    meta: {},
    component: () => import("@/views/form_link/Read.vue"),
  },
];
