<template>
  <nav>
    <v-app-bar flat app class="grey darken-4">
      <v-app-bar-nav-icon
        v-if="loggedIn"
        color="white"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer />

      <img
        src="@/assets/ea_logo_inverted.svg"
        style="max-width: 200px"
        class="d-sm-none"
      />
      <img
        src="@/assets/ea_logo_inverted.svg"
        style="max-width: 250px"
        class="d-none d-sm-flex"
      />

      <v-spacer />

      <authentication></authentication>

      <!-- <v-btn color="grey darken-2" class="white--text">
        <span>Sign In</span>
        <v-icon color="white" right>mdi-account-circle</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-navigation-drawer
      v-if="loggedIn"
      app
      class="grey darken-4"
      v-model="drawer"
    >
      <v-list>
        <v-list-item @click="$router.push({ path: '/' })">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-model="expand_my_account"
          prepend-icon="mdi-account-circle-outline"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text"
              >My Account</v-list-item-title
            >
          </template>

          <v-list-item link to="/myAccount/info" class="ml-3">
            <v-list-item-title class="sub_item_title" right
              >My Information</v-list-item-title
            >
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-model="expand_mortgages"
          prepend-icon="mdi-cart-plus"
          color="white"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">Mortgages</v-list-item-title>
          </template>

          <v-list-item
            link
            to="/dashboard/mortgagesAvailable/list"
            class="ml-3"
          >
            <v-list-item-title class="sub_item_title" right
              >Active Loans Available</v-list-item-title
            >
          </v-list-item>

          <v-list-item link to="/dashboard/mortgages/my-bids" class="ml-3">
            <v-list-item-title class="sub_item_title" right>
              My Bids
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-model="expand_admin"
          prepend-icon="mdi-shield-crown-outline"
          color="white"
          v-if="admin"
        >
          <template v-slot:activator>
            <v-list-item-title class="white--text">Admin</v-list-item-title>
          </template>

          <v-list-group :value="false" sub-group color="white">
            <template v-slot:activator>
              <v-list-item-title class="white--text">Deals</v-list-item-title>
            </template>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'all' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right
                >List All Deals</v-list-item-title
              >
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'In Progress' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Active Deals
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'Active Deal Summaries' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Active Deal Summary
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'Funded' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Funded Deals
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'Closed' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Closed Deals
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/deal/list',
                query: { status: 'Not Completed / Archive' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Archived Deals
              </v-list-item-title>
            </v-list-item>

            <v-list-item link to="/dashboard/admin/deal/add" class="ml-3">
              <v-list-item-title class="sub_item_title" right>
                + Add New Deal
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                Customers
              </v-list-item-title>
            </template>

            <v-list-item
              link
              to="/dashboard/admin/customer/list-all-customers"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                List All Customers
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              to="/dashboard/admin/customer/create"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Create Customer
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                Properties
              </v-list-item-title>
            </template>

            <v-list-item
              link
              to="/dashboard/admin/list-all-properties"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                List All Properties
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              to="/dashboard/admin/property/create"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Create Property
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text"
                >Companies</v-list-item-title
              >
            </template>

            <!-- <v-list-item link to="/listCompanyApplications" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >Company Applications</v-list-item-title
              >
            </v-list-item> -->
            <v-list-item link to="/dashboard/admin/company/list" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >List Companies</v-list-item-title
              >
            </v-list-item>

            <v-list-item link to="/dashboard/admin/company/add" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >Add Company</v-list-item-title
              >
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">Users</v-list-item-title>
            </template>

            <!-- <v-list-item link to="/listCompanyApplications" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >Company Applications</v-list-item-title
              >
            </v-list-item> -->
            <v-list-item link to="/dashboard/admin/user/list" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >List Users</v-list-item-title
              >
            </v-list-item>

            <v-list-item link to="/dashboard/admin/user/add" class="ml-3">
              <v-list-item-title class="sub_item_title" right
                >Add User</v-list-item-title
              >
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                Kanban
              </v-list-item-title>
            </template>
            <v-list-item link to="/dashboard/admin/kanban" class="ml-3">
              <v-list-item-title class="sub_item_title text-subtitle-2" right>
                Stages
              </v-list-item-title>
            </v-list-item>

            <!-- <v-list-item link to="/dashboard/admin/my_task" class="ml-3">
              <v-list-item-title class="sub_item_title text-subtitle-2" right>
                My Task
              </v-list-item-title>
            </v-list-item> -->

            <v-list-item
              link
              to="/dashboard/admin/task_manager/list"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title text-subtitle-2" right>
                Task Manager
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                Funded Mortgage
              </v-list-item-title>
            </template>

            <v-list-item
              link
              to="/dashboard/admin/funded_mortgage/list"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                List Funded Mortgage
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                My Task
              </v-list-item-title>
            </template>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/task/list',
                query: { type: 'all' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                List All Tasks
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/task/list',
                query: { type: 'new deals' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                New Deals
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/task/list',
                query: { type: 'renewals' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Renewals
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/task/list',
                query: { type: 'support' },
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Support
              </v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="false" color="white" sub-group>
            <template v-slot:activator>
              <v-list-item-title class="white--text">
                Inquiries
              </v-list-item-title>
            </template>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/inquiry/loans',
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Loans
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              exact
              :to="{
                path: '/dashboard/admin/inquiry/archives',
              }"
              class="ml-3"
            >
              <v-list-item-title class="sub_item_title" right>
                Archives
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog
      v-if="loggedIn && admin"
      v-model="search_will_show"
      fullscreen
      hide-overlay
      transition="custom-transition"
    >
      <ReadSearch
        v-if="search_will_show"
        ref="read_search"
        @close="search_will_show = false"
      />
    </v-dialog>
  </nav>
</template>

<script>
import Authentication from "@/components/Authentication";
import ReadSearch from "@/components/search/Read_Search.vue";

export default {
  components: { Authentication, ReadSearch },
  data() {
    return {
      name: "navbar",
      components: {
        Authentication,
      },
      drawer: null,

      expand_my_account: true,
      expand_mortgages: true,
      expand_admin: true,
      search_will_show: false,
    };
  },

  methods: {
    handleKeyDown(event) {
      if (event.altKey && event.key.toLowerCase() === "t") {
        event.preventDefault();

        this.search_will_show = true;

        setTimeout(() => {
          this.$refs.read_search.focusTextField();
        }, 0);
      }

      if (event.key.toLowerCase() === "escape") {
        event.preventDefault();
        this.search_will_show = false;
      }
    },
  },

  created() {
    window.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    // Clean up event listener when component is destroyed
    window.removeEventListener("keydown", this.handleKeyDown);
  },

  computed: {
    admin() {
      if (this.$store.getters["Auth/getAuthUser"].admin) {
        return true;
      } else {
        return false;
      }
    },
    loggedIn() {
      //todo this should be if you are logged in AND account.status is not null
      let getLoggedIn = this.$store.getters["Auth/getLoggedIn"];
      // let getAuthUser = this.$store.getters["Auth/getAuthUser"];

      // console.log("in the computer loggedIn of navbar, logging getAuthUser");
      // console.log(getAuthUser);
      // if (getLoggedIn && getAuthUser.account) {
      //   if (
      //     Object.keys(getAuthUser.account).includes("status") &&
      //     getAuthUser.account.status == "active"
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
      if (getLoggedIn) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.cannonIcon {
  height: 30px;
}
.v-list-item__title.sub_item_title {
  font-size: 0.75rem;
}

.custom-transition .v-dialog {
  transition: transform 0.9s ease-in-out;
}

.custom-transition-enter-active .v-dialog,
.custom-transition-leave-active .v-dialog {
  transform: translateY(-100%);
}

.custom-transition-enter-to .v-dialog,
.custom-transition-leave .v-dialog {
  transform: translateY(0);
}
</style>
