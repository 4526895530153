<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" xl="8">
        <div>
          <v-img
            src="@/assets/lender_portal_homepage.jpg"
            :aspect-ratio="aspectRatio"
            class="darkened-image"
          ></v-img>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col xs="12" xl="8" class="d-flex justify-center">
        <div style="max-width: 90%">
          <v-row>
            <v-col cols="12" md="6">
              <v-card flat style="background-color: transparent">
                <v-card-text class="text-subtitle-1">
                  <p class="text-h5 text-center">
                    Welcome to the Lender Portal
                  </p>

                  <p class="text-justify">
                    Investing in private mortgages is planting seeds of
                    opportunity. Nurture them with wisdom and patience, and
                    watch as your financial garden flourishes into abundance.
                  </p>
                  <p class="text-justify">
                    With EquityApproved.ca, registered lenders can access the
                    Lender Portal designed to self manage investments
                    efficiently and explore new lending opportunities. We
                    provide full support to our lenders at every stage of their
                    mortgage and streamline the process. Interested in becoming
                    a lender? Please reach out to us
                    <a href="mailto:info@equityapproved.ca"> here</a>
                    to learn more about our process.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6">
              <v-card flat style="background-color: transparent">
                <v-card-text class="text-subtitle-1 text-center">
                  <p class="text-h5">Features</p>
                  View current available mortgages for Funding
                  <br />Submit offers on lending opportunities <br />Create and
                  track service tickets on your loans <br />View payment history
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <!-- 
        <p>
          As a registered lender, you will be able to do the following regarding
          lending opportunities:
        </p>
        <ul>
          <li>View current available mortgages for funding or sale</li>
          <li>Submit offers on mortgages</li>
          <li>View current offers on lending opportunity</li>
          <li v-if="loggedIn">
            Track status of the funding of your loans
            <span style="color: green !important">(Coming Soon!)</span>
          </li>
          <li v-if="loggedIn">
            Make service requests for your loans
            <span style="color: green !important">(Coming Soon!)</span>

            <ul v-if="loggedIn">
              <li>NSF notifications</li>
              <li>Payout requests</li>
              <li>Insurance requests</li>
            </ul>
          </li>
        </ul>

        <p class="mt-4" v-if="!loggedIn">
          To gain lender access, you must be an EquityApproved.ca approved and
          onboarded lender. Please contact us to inquire about joining our
          network.
        </p> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      quote: null,
      windowWidth: window.innerWidth,
    };
  },
  components: {},
  computed: {
    homeImg() {
      return this.$store.getters["Auth/getLoggedIn"];
    },

    loggedIn() {
      let user_logged_in = this.$store.getters["Auth/getLoggedIn"];

      if (user_logged_in) {
        return true;
      } else {
        return false;
      }
    },

    aspectRatio() {
      if (this.windowWidth >= 1264) {
        return 3;
      } else if (this.windowWidth >= 960) {
        return 2.5;
      } else if (this.windowWidth >= 600) {
        return 2;
      } else {
        return 1.5;
      }
    },
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async created() {},
  async mounted() {
    // if ('account' in this.$store.getters["Auth/getAuthUser"] && 'Companies' in this.$store.getters["Auth/getAuthUser"].account) {
    //   if (!this.$store.getters["Auth/getAuthUser"].account.Companies.length) {
    //     this.$router.push('/companyAccountApplication')
    //   }
    // }
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.gifWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cannonGif {
  min-width: 700px;
  max-width: 700px;
  min-height: 400px;
  max-height: 400px;
  margin-top: 5%;
  border: 10px solid rgb(39, 39, 39);
}
</style>
