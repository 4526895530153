export default [
  {
    path: "/dashboard/admin/customer/list-all-customers",
    props: true,
    name: "listAllCustomers",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/customer/List.vue"),
  },

  {
    path: "/dashboard/admin/customer/read/:id",
    props: true,
    name: "readCustomer",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/customer/Read.vue"),
  },

  {
    path: "/dashboard/admin/customer/:viewed_as",
    props: true,
    name: "createCustomer",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/customer/Add.vue"),
  },
];
