var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","xl":"8"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{staticClass:"d-flex align-center"},[_c('v-text-field',{ref:"searchField",attrs:{"label":"Search Here","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handle_search_btn.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"teal ml-4 mt-2",attrs:{"large":""},on:{"click":_vm.handle_search_btn}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-magnify")]),_vm._v(" search ")],1)],1)],1),(_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"text-center text-subtitle-1"},[_c('v-progress-circular',{attrs:{"size":30,"color":"primary","indeterminate":""}}),_vm._v(" Please be patient while we search for the data you requested. ")],1)],1):_vm._e(),(!_vm.loading && _vm.searchedData.length > 0)?_c('v-row',[_c('v-col',[_c('v-list',[_c('v-subheader',[_vm._v("Most Recent")]),_c('v-divider'),_c('v-list',{attrs:{"three-line":""}},_vm._l((_vm.searchedData),function(searched){return _c('v-list-item',{key:searched.id,on:{"click":function($event){$event.stopPropagation();return _vm.handle_click_customer(
                      searched.id,
                      searched.username ? 'User' : 'Customer'
                    )}}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"teal",attrs:{"dark":""}},[_vm._v(" mdi-account-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(searched.last_name)+", "+_vm._s(searched.first_name)+" ")]),_c('div',{staticClass:"ml-auto grey--text"},[_vm._v(" "+_vm._s(_vm.date_modified(searched.updatedAt))+" ")])]),_c('v-list-item-subtitle',[_c('div',[_vm._v(" Type: "+_vm._s(searched.username ? "User" : "Customer")+" ")]),_vm._l((searched.contact_infos),function(contact,index){return _c('span',{key:contact.id},[_vm._v(" "+_vm._s(contact.type)+": "+_vm._s(contact.number)+" "+_vm._s(contact.email)+" "),(index + 1 !== searched.contact_infos.length)?_c('span',[_vm._v(" - ")]):_vm._e()])})],2),_c('v-list-item-subtitle',[_c('v-list',{staticClass:"rounded-lg",attrs:{"three-line":""}},[(
                            searched.int_custs &&
                            searched.int_custs.length === 0
                          )?_c('v-subheader',[_vm._v(" No Associated Deals ")]):_vm._e(),(
                            searched.companies &&
                            searched.companies.length === 0
                          )?_c('v-subheader',[_vm._v(" No Associated Companies ")]):_vm._e(),(searched.companies)?_c('v-list',{attrs:{"three-line":""}},_vm._l((searched.companies),function(company){return _c('v-list-item',{key:company.id,attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.handle_click_company(company.id)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-domain ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" Company: "+_vm._s(company.name)+" ")]),_c('div',[_vm._v(" Type: "+_vm._s(company.lender ? "Lender" : "")+" "+_vm._s(company.lender && company.appraiser ? " - " : "")+" "+_vm._s(company.appraiser ? "Appraiser" : "")+" "+_vm._s((company.lender || company.appraiser) && company.lawyer ? " - " : "")+" "+_vm._s(company.lawyer ? "Lawyer" : "")+" ")])])],1)],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"grey--text text-body-1"},[_vm._v(" mdi-clock-outline ")]),_vm._v(" "+_vm._s(_vm.date_modified(company.updatedAt))+" ")],1)],1)],1)}),1):_vm._e(),(searched.int_custs)?_c('v-list',{attrs:{"three-line":""}},_vm._l((searched.int_custs.filter(
                              (c) => c.deal
                            )),function(int_cust){return _c('v-list-item',{key:int_cust.id,staticClass:"hoverable",attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();return _vm.handle_click_deal(int_cust.deal.id)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-folder-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" Public Name: "+_vm._s(int_cust.deal.public_name)+" ")]),_c('div',[_vm._v(" Status: "+_vm._s(int_cust.deal.status)+" ")])])],1)],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"grey--text text-body-1"},[_vm._v(" mdi-clock-outline ")]),_vm._v(" "+_vm._s(_vm.date_modified(int_cust.updatedAt))+" ")],1)],1),_c('v-btn',{staticClass:"hidden-btn",attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handle_click_objective(int_cust.deal)}}},[_vm._v(" "+_vm._s(int_cust.deal && int_cust.deal.stg_itms.find( (s) => s.kbn_stg.id === int_cust.deal.kbn_stg.id ).stg_objs.length == 0 ? "Add Objective" : "View Objectives")+" ")])],1)}),1):_vm._e()],1)],1)],1)],1)}),1)],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }