export function getToken ( state ) {

    return state.token;

}

export function getTokenExpiry ( state ) {
    return state.expiresOn;
}

export function getLoggedIn ( state ) {

    if(state.loggedIn) {
        return true
    } else {
        return false
    }



}


export function getAuthUser ( state ) {

    return state.user;

}

