export default 
[
      { path: '/dashboard/admin/company/add', 
      props: true, 
      name: 'company_add', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/Add.vue') },

      { path: '/dashboard/admin/company/edit/:company_id', 
      props: true, 
      name: 'company_edit', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/Add.vue') },

      { path: '/dashboard/admin/company/list', 
      props: true, 
      name: 'company_list', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/List.vue') },

      { path: '/dashboard/admin/company/read/:company_id', 
      props: true, 
      name: 'company_read', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/Read.vue') },

      { path: '/dashboard/admin/company/associate_user/', 
      props: true, 
      name: 'company_associate_user', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/AssociateUser.vue') },

      { path: '/dashboard/admin/company/:company_id/investor_kyc', 
      props: true, 
      name: 'investor_kyc', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/CreateInvestorKYC.vue') },

      { path: '/dashboard/admin/company/:company_id/kyc/homepage', 
      props: true, 
      name: 'kyc_homepage', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/KYCHomepage.vue') },

      { path: '/kyc_hompage_link/read', 
      props: true, 
      name: 'kyc_homepage_link', 
      // does not need to be logged in because has token in the link
      meta: {}, 
      component: () => import('@/views/kyc_homepage_link/Read.vue') },

      { path: '/dashboard/admin/company/account/:viewed_as/:id', 
      props: true, 
      name: 'create_or_update_account', 
      meta: { adminOnly: true }, 
      component: () => import('@/views/admin/company/account/Add.vue') },

]