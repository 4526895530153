const { DateTime } = require("luxon");

module.exports = {
  dateFormat(value) {
    let d = new Date(value);
    return d.toLocaleDateString("en-US");
  },

  formatPrice(value) {
    if (!value) {
      return "$0.00";
    }
    let numberValue = parseFloat(value.replace(/[$,]/g, ""));
    if (isNaN(numberValue)) {
      return "$0.00";
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(numberValue);
  },

  booleanString(x) {
    if (x == true) {
      return "Yes";
    } else {
      return "No";
    }
  },

  capitalize(value) {
    return value.toUpperCase();
  },

  capitalize_first_letter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },

  capitalize_each_word(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  },

  currency(value) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(parseInt(value));
  },
  mortgagePosition(value) {
    if (value == 1) {
      return "1st";
    }
    if (value == 2) {
      return "2nd";
    }
    if (value == 3) {
      return "3rd";
    }
    if (value == 4) {
      return "4th";
    }
    if (value == 5) {
      return "5th";
    }
  },
  yes_no(value) {
    if (value) {
      return "Yes";
    } else {
      return "No";
    }
  },

  province(value) {
    switch (value) {
      case "AB":
        return "Alberta";

      case "BC":
        return "British Columbia";

      case "ON":
        return "Ontario";
    }
  },

  formatDate(value) {
    if (value === null || value === "") {
      return null;
    }

    // Convert the selected date to a JavaScript Date object
    const newDate = DateTime.fromISO(value, { zone: "utc" });

    // Ensure that the date is interpreted as UTC, regardless of the user's time zone
    const dateUTC = newDate.setZone("utc", { keepLocalTime: true });

    const options = { month: "long", day: "numeric", year: "numeric" };
    return dateUTC.toLocaleString(options);
  },

  byteToMegabyte(value) {
    const fileSizeInMB = value / 1000000;
    return `${fileSizeInMB.toFixed(2)} MB`;
  },
};
