export default [
  {
    path: '/dashboard/admin/deal/:deal_id/:viewed_as/:customer_id/read',
    props: true,
    name: 'customer_read',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/customer/Read.vue'),
  },

  {
    path: '/dashboard/admin/deal/assign_deal_summary/:deal_summary_id',
    props: true,
    name: 'assign_deal_summary',
    meta: { adminOnly: true },
    component: () =>
      import('@/views/admin/deal/deal_summary/AssignLenders.vue'),
  },

  {
    path: '/dashboard/admin/deal/resend_to_lenders/:deal_summary_id',
    props: true,
    name: 're-assign_deal_summary',
    meta: { adminOnly: true },
    component: () =>
      import('@/views/admin/deal/deal_summary/ResendToLenders.vue'),
  },

  {
    path: '/dashboard/admin/deal/list',
    name: 'deal_list',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/List.vue'),
  },

  {
    path: '/dashboard/admin/deal_summary/add/:deal_id',
    props: true,
    name: 'deal_summary_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/deal_summary/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal_summary/edit/:deal_summary_id',
    props: true,
    name: 'deal_summary_edit',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/deal_summary/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/read/:deal_id',
    props: true,
    name: 'dealRead',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Read.vue'),
  },

  {
    path: '/dashboard/admin/deal/add',
    props: true,
    name: 'deal_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/edit/:deal_id',
    props: true,
    name: 'deal_edit',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/renew/:deal_id',
    props: true,
    name: 'deal_renew',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/Add.vue'),
  },

  {
    path: '/dashboard/admin/deal/funded_mortgage/:deal_id/:viewed_as',
    props: true,
    name: 'funded_mortgage_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/deal/funded_mortgage/Add.vue'),
  },  
];
