export default [
  {
    path: '/dashboard/admin/kanban',
    props: true,
    name: 'kanban',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/ReadStages.vue'),
  },

  {
    path: '/dashboard/admin/kanban/manage_stages/:type',
    props: true,
    name: 'manage_stages',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/ManageStages.vue'),
  },

  {
    path: '/dashboard/admin/kanban/manage_stages/add/:type',
    props: true,
    name: 'add_stage',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/AddStage.vue'),
  },

  {
    path: '/dashboard/admin/kanban/manage_stages/update/:type/:id',
    props: true,
    name: 'update_stage',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/UpdateStage.vue'),
  },

  {
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/list/',
    props: true,
    name: '',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/List.vue'),
  },

  {
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/read/:stage_objective_id',
    props: true,
    name: '',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/ReadObjective.vue'),
  },

  {
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/:stage_objective_id/task/list/',
    props: true,
    name: '',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/task/List.vue'),
  },

  { 
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/:stage_objective_id/task/read/:stage_objective_task_id',
    props: true,
    name: '',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/task/ReadTask.vue'),
  },

  { 
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/add',
    props: true,
    name: '',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/AddStageObjective.vue'),
  },
  
  {
    path: '/dashboard/admin/my_task',
    props: true,
    name: 'my_task',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/ReadTaskDashboard.vue'),
  },
  
  {
    path: '/dashboard/admin/kanban/:kanban_type/stage-item/:stage_item_id/objective/:stage_objective_id/task/:stage_objective_task_id/helper/:helper_id/read',
    props: true,
    name: 'helper_read',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/objective/task/helper/ReadHelpers.vue'),
  },
  
  {
    path: '/dashboard/admin/task_manager/list',
    props: true,
    name: 'task_manager_list',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/task_manager/List.vue'),
  },
  
  {
    path: '/dashboard/admin/task_manager/objective/:view_type',
    props: true,
    name: 'task_manager_add',
    meta: { adminOnly: true },
    component: () => import('@/views/admin/kanban/task_manager/Add.vue'),
  },
];
