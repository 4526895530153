export default [
  {
    path: "/dashboard/admin/inquiry/loans",
    props: true,
    name: "Loans",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/inquiry/Loan.vue"),
  },
  
  {
    path: "/dashboard/admin/inquiry/archives",
    props: true,
    name: "Archives",
    meta: { adminOnly: true },
    component: () => import("@/views/admin/inquiry/Archive.vue"),
  },
];
