<template>
  <v-row justify="center">
    <v-col xs="12" xl="8">
      <v-card>
        <v-card-text>
          <v-row class="my-2">
            <v-col class="d-flex align-center">
              <v-text-field
                ref="searchField"
                v-model="search"
                label="Search Here"
                hide-details
                @keyup.enter="handle_search_btn"
              />

              <v-btn class="teal ml-4 mt-2" large @click="handle_search_btn">
                <v-icon class="mr-2">mdi-magnify</v-icon>
                search
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="loading">
            <v-col class="text-center text-subtitle-1">
              <v-progress-circular :size="30" color="primary" indeterminate />
              Please be patient while we search for the data you requested.
            </v-col>
          </v-row>

          <v-row v-if="!loading && searchedData.length > 0">
            <v-col>
              <v-list>
                <v-subheader>Most Recent</v-subheader>

                <v-divider />

                <v-list three-line>
                  <v-list-item
                    v-for="searched in searchedData"
                    :key="searched.id"
                    @click.stop="
                      handle_click_customer(
                        searched.id,
                        searched.username ? 'User' : 'Customer'
                      )
                    "
                  >
                    <v-list-item-avatar>
                      <v-icon class="teal" dark> mdi-account-outline </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="d-flex">
                        <div>
                          {{ searched.last_name }}, {{ searched.first_name }}
                        </div>

                        <div class="ml-auto grey--text">
                          {{ date_modified(searched.updatedAt) }}
                        </div>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div>
                          Type: {{ searched.username ? "User" : "Customer" }}
                        </div>

                        <span
                          v-for="(contact, index) in searched.contact_infos"
                          :key="contact.id"
                        >
                          {{ contact.type }}: {{ contact.number }}
                          {{ contact.email }}
                          <span
                            v-if="index + 1 !== searched.contact_infos.length"
                          >
                            -
                          </span>
                        </span>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        <v-list three-line class="rounded-lg">
                          <v-subheader
                            v-if="
                              searched.int_custs &&
                              searched.int_custs.length === 0
                            "
                          >
                            No Associated Deals
                          </v-subheader>

                          <v-subheader
                            v-if="
                              searched.companies &&
                              searched.companies.length === 0
                            "
                          >
                            No Associated Companies
                          </v-subheader>

                          <v-list three-line v-if="searched.companies">
                            <v-list-item
                              v-for="company in searched.companies"
                              :key="company.id"
                              link
                              @click.stop="handle_click_company(company.id)"
                            >
                              <v-list-item-avatar>
                                <v-icon> mdi-domain </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                  <v-row>
                                    <v-col>
                                      <div>
                                        Company:
                                        {{ company.name }}
                                      </div>

                                      <div>
                                        Type:
                                        {{ company.lender ? "Lender" : "" }}
                                        {{
                                          company.lender && company.appraiser
                                            ? " - "
                                            : ""
                                        }}
                                        {{
                                          company.appraiser ? "Appraiser" : ""
                                        }}
                                        {{
                                          (company.lender ||
                                            company.appraiser) &&
                                          company.lawyer
                                            ? " - "
                                            : ""
                                        }}
                                        {{ company.lawyer ? "Lawyer" : "" }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                  <v-icon class="grey--text text-body-1">
                                    mdi-clock-outline
                                  </v-icon>
                                  {{ date_modified(company.updatedAt) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-list three-line v-if="searched.int_custs">
                            <v-list-item
                              v-for="int_cust in searched.int_custs.filter(
                                (c) => c.deal
                              )"
                              :key="int_cust.id"
                              link
                              @click.stop="handle_click_deal(int_cust.deal.id)"
                              class="hoverable"
                            >
                              <v-list-item-avatar>
                                <v-icon> mdi-folder-outline </v-icon>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-subtitle class="text--primary">
                                  <v-row>
                                    <v-col>
                                      <div>
                                        Public Name:
                                        {{ int_cust.deal.public_name }}
                                      </div>

                                      <div>
                                        Status:
                                        {{ int_cust.deal.status }}
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-list-item-subtitle>

                                <v-list-item-subtitle>
                                  <v-icon class="grey--text text-body-1">
                                    mdi-clock-outline
                                  </v-icon>
                                  {{ date_modified(int_cust.updatedAt) }}
                                </v-list-item-subtitle>
                              </v-list-item-content>

                              <v-btn
                                class="hidden-btn"
                                @click.stop="
                                  handle_click_objective(int_cust.deal)
                                "
                                text
                                small
                              >
                                {{
                                  int_cust.deal &&
                                  int_cust.deal.stg_itms.find(
                                    (s) =>
                                      s.kbn_stg.id === int_cust.deal.kbn_stg.id
                                  ).stg_objs.length == 0
                                    ? "Add Objective"
                                    : "View Objectives"
                                }}
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-list>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";

export default {
  data: () => ({
    loading: false,

    search: "",

    deals: [],

    searchedData: [],
  }),

  methods: {
    focusTextField() {
      this.$refs.searchField.focus();
    },

    date_modified(date) {
      let given_date = new Date(date);
      let today = new Date();
      let diff = today - given_date;
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      let daysLabel = days === 1 ? "day" : "days";
      let hoursLabel = hours === 1 ? "hour" : "hours";

      if (days === 0) {
        return `${hours} ${hoursLabel} ago`;
      } else if (hours === 0) {
        return `${days} ${daysLabel} ago`;
      } else {
        return `${days} ${daysLabel}, ${hours} ${hoursLabel} ago`;
      }
    },

    async update_kanban_stage(deal) {
      try {
        const res = await API().post("api/kanban/update_kanban_stage_item", {
          stage_id: deal.kbn_stg.id,
          item_id: deal.id,
          type: "Deal",
          choice: "Archive and Create New",
        });

        console.log("res: ", res);

        const stg_itm_id = res.data.stg_itms.find(
          (s) => s.kbn_stg.id === deal.kbn_stg.id
        ).id;

        // after updating the kanban stage, go to the add objective page
        this.$router.push({
          path: `/dashboard/admin/kanban/Deal/stage-item/${stg_itm_id}/objective/add`,
          query: { view_type: "deal", deal_id: deal.id },
        });

        this.$emit("close");
      } catch (error) {
        console.log(error);
      }
    },

    handle_click_customer(id, type) {
      if (type === "User") {
        this.$router.push({
          path: `/dashboard/admin/user/read/${id}`,
        });

        this.$emit("close");
        return;
      }

      if (type === "Customer") {
        this.$router.push({
          path: `/dashboard/admin/customer/read/${id}`,
        });

        this.$emit("close");
        return;
      }
    },

    handle_click_deal(deal_id) {
      this.$router.push({
        path: `/dashboard/admin/deal/read/${deal_id}`,
      });

      this.$emit("close");
    },

    handle_click_company(company_id) {
      this.$router.push({
        path: `/dashboard/admin/company/read/${company_id}`,
      });

      this.$emit("close");
    },

    handle_click_objective(deal) {
      const stg_itm = deal.stg_itms.find(
        (s) => s.kbn_stg.id === deal.kbn_stg.id
      );

      // if deal is not yet in the stage, go to update deal to update stage
      if (!deal.kbn_stg) {
        this.$router.push({
          path: `/dashboard/admin/deal/edit/${deal.id}`,
        });

        this.$emit("close");
        return;
      }

      // if deal is in the stage but no stage item, update kanban stage to create stage item
      if (deal.kbn_stg && !stg_itm) {
        this.update_kanban_stage(deal);
        return;
      }

      // if there are objectives, go to the list page
      if (stg_itm.stg_objs.length > 0) {
        this.$router.push({
          path: `/dashboard/admin/kanban/Deal/stage-item/${stg_itm.id}/objective/list/`,
          query: { view_type: "searched", deal_id: deal.id },
        });
        this.$emit("close");
        return;
      }

      // if there are no objective, go to the add page
      if (stg_itm.stg_objs.length == 0) {
        this.$router.push({
          path: `/dashboard/admin/kanban/Deal/stage-item/${stg_itm.id}/objective/add`,
          query: { view_type: "deal", deal_id: deal.id },
        });
        this.$emit("close");
        return;
      }
    },

    async handle_search_btn() {
      try {
        this.loading = true;
        const response = await API().get(
          `api/internal-admin/search/customer_and_user?search_query=${this.search}`
        );

        if (response.status === 200) {
          console.log("response: ", response);
          this.loading = false;
          this.searchedData = response.data;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  mounted() {
    this.handle_search_btn();
  },
};
</script>

<style scoped>
.hoverable:hover .hidden-btn {
  display: block !important;
}

.hidden-btn {
  display: none;
}
</style>
